import React from "react"
//import 'uikit/dist/css/uikit.css'
import "./cta-banner.scss"
import CustomMarkdown from "../../utils/customMarkdown"
import { AiOutlineRight } from 'react-icons/ai'

const CTABanner = ({ Title, Subtitle, ctaLabel, ctaUrl }) => {


    return (
        <>
            <div className="cta-banner-wrapper">
                <div className="cta-banner-container">
                    <div className="cta-text">
                        <section>
                            {
                                Title ? <CustomMarkdown children={Title}></CustomMarkdown> : ""
                            }
                            {
                                Subtitle ? <CustomMarkdown children={Subtitle}></CustomMarkdown> : ""
                            }
                        </section>
                    </div>
                    <div className="cta-banner-container-ctas">
                        <a className="cta-banner-container-primary-cta" href={ctaUrl}>
                            {ctaLabel} <span className="cta-arrow-right"><AiOutlineRight /></span>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )

}

export default CTABanner