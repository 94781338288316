import React, { useState, useEffect } from "react"
import "./header.scss"
//import 'uikit/dist/css/uikit.css'


import BackgroundImage from "gatsby-background-image"

//import { GatsbyImage } from "gatsby-plugin-image"
import CustomMarkdown from "../../utils/customMarkdown"

import { AiOutlineRight } from "react-icons/ai"
import { GatsbyImage } from "gatsby-plugin-image"
import LogoTransition from "../logo-transition/logo-transition"


const Header = ({ title, markdownTitle, subtitle, secondarySubtitle, CTAText, CTAUrl, Disclaimer, Background, image, HeaderHeight, CTA_nofollow, FirstLogo, SecondLogo }) => {
    var headerStyle
    Background ? headerStyle = { backgroundImage: "url(" + Background + ")" } : headerStyle = ""

    var headerHeightStyle
    switch (HeaderHeight) {
        case "small":
            headerHeightStyle = { minHeight: '200px' }
            break;
        case "medium":
            headerHeightStyle = { minHeight: '380px' }
            break;
        case "large":
            headerHeightStyle = { minHeight: '600px' }
            break;

        default:
            break;
    }

    useEffect(() => {
        if (Background && Background.fluid && Background.fluid && Background.fluid.srcWebp) {
            const preloadLink = document.createElement("link");
            preloadLink.href = Background.fluid.srcWebp;
            preloadLink.rel = "preload";
            preloadLink.as = "image";
            document.head.appendChild(preloadLink);
        }
        // console.log("background", Background)
        // console.log("SecondaryImage", SecondaryImage) 

    }, [])



    return (
        <>
            <BackgroundImage Tag="section" role="img" fluid={Background.fluid}>
                {/* {
                    image ?
                        <GatsbyImage image={image.gatsbyImageData} alt={"wayfarers-overlay-logo"}></GatsbyImage>
                        : ""
                } */}
                {/* {
                    markdownTitle === "# Bursting with **Gift Ideas**" ? 
                    // <StaticImage src='../../assets/wayfarersOverlay.png' alt="wf-logo-overlay" className="wf-overlay"/> 
                    // <GatsbyImage image={image3.gatsbyImageData} alt="How Comparison works - Step 3" className="item-image" objectFit="contain"></GatsbyImage>

                    <GatsbyImage className="wf-overlay" Image={image.gatsbyImageData} alt="wf-logo-overlay"></GatsbyImage> 
                    : ""
                } */}
                <div className="relative-position">
                    {/* <div className="gatsby-image-absolut">
                    <GatsbyImage image={Background.gatsbyImageData} alt={""}></GatsbyImage>

                </div> */}
                    {
                        image ?
                            <GatsbyImage className="wf-overlay" image={image.gatsbyImageData} alt="wf-logo-overlay"></GatsbyImage>
                            : ""
                    }
                     
                    {/* {
                        FirstLogo && SecondLogo ?
                            <div className={nextLogo ? "other" : "bar"}>

                                <GatsbyImage className="logo logo1" image={FirstLogo.gatsbyImageData} alt="wf-logo-1"></GatsbyImage>
                                <GatsbyImage className="logo logo2" image={SecondLogo.gatsbyImageData} alt="wf-logo-2"></GatsbyImage>

                            </div>
                            : ""

                    }
                     */}

                    <div className="header" style={headerHeightStyle}>


                        <div className="header-content">

                            {
                                title ? <h1 className="header__title">{title}</h1> : markdownTitle ? <CustomMarkdown style={{ color: "white !important" }} className="header__title" children={markdownTitle}></CustomMarkdown> : ""
                            }

                            {subtitle ? <CustomMarkdown className="header__subtitle" children={subtitle}></CustomMarkdown> : ""}


                            {
                                CTAText ?
                                    <div className="header__cta">
                                        <a href={CTAUrl} className="cta" rel={CTA_nofollow ? "nofollow" : ""}>{CTAText}<span className="arrow-right" ><AiOutlineRight /></span></a>
                                    </div>
                                    : ""
                            }


                            {secondarySubtitle ? <CustomMarkdown className="header__subtitle" children={secondarySubtitle}></CustomMarkdown> : ""}

                            <div className="cpg-disclaimer">
                                {Disclaimer ? Disclaimer : ""}
                            </div>


                        </div>

                    </div>


                </div>

            </BackgroundImage>

        </>
    )
}

export default Header