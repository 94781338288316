import React from "react"
import CustomMarkdown from "../../utils/customMarkdown"
//import 'uikit/dist/css/uikit.css'

import "./paragraph.scss"

const Paragraph = ({ title, text, subtitle, bgColor }) => {


    return (

        <div className="paragraph-container" style={bgColor ? {backgroundColor: bgColor} : ""}>
            <div className="paragraph-content">
                <div className="paragraph-header">
                    <h3>{title}</h3>
                    <CustomMarkdown children={subtitle}></CustomMarkdown>
                </div>
                <div className="paragraph-description">
                    <CustomMarkdown children={text}></CustomMarkdown>
                </div>
            </div>
        </div>
    )

}

export default Paragraph