import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
//import 'uikit/dist/css/uikit.css'
import "./banner.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import CustomMarkdown from "../../utils/customMarkdown"

const Banner = ({ title, subtitle, text, imagePosition, image, imageAlt, dark = true }) => {

    return (
        <>
            <div className="price-guarantee-container">
                <div className={"price-guarantee" + (dark == true ? "" : " dark") + " padding-top padding-bottom"}>
                    <div className="price-guarantee__content uk-align-center padding-top padding-bottom" data-uk-grid>
                        <div
                            data-uk-grid>

                            {image ?
                                <div className="uk-width-2-5@s uk-hidden@s price-guarantee__content__image ">
                                    <GatsbyImage image={image} alt={""}></GatsbyImage>
                                </div> : ""}



                            <div className="uk-width-3-5@s uk-align-center no-padding-left">
                                {title && subtitle ? <h2 id="main-text-color">{title}<br /><span className="price-guarantee-subtitle">{subtitle}</span></h2> : ""}
                                <CustomMarkdown children={text} skipHtml={false} className="price-guarantee__content__text"></CustomMarkdown>
                            </div>


                            <div className="uk-width-2-5@s uk-visible@s price-guarantee__content__image uk-align-center">
                                <GatsbyImage image={image} alt={"item.ALT"}></GatsbyImage>
                            </div>
                        </div>



                    </div>
                </div>
            </div>

        </>
    )

}

export default Banner