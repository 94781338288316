import React from "react"
// import {Link, useStaticQuery, graphql } from "gatsby"
// import ReactMarkdown from "react-markdown"
//import 'uikit/dist/css/uikit.css'
import "./comparison.scss"
import { GatsbyImage } from "gatsby-plugin-image"



const Comparison = ({title, step1, step2, step3, image1, image2, image3}) => {

    return(
        <div className=" padding-top padding-bottom lateral-padding">
            <div className="how-works ">
                <h2 className="how-works__card-title">{title}</h2>
                <div className="how-works__container uk-child-width-1-3@s uk-grid-small uk-margin-remove uk-text-center" data-uk-grid>
                    <div className="how-works__container_item">
                        {/* <img src={image1} alt="How Comparison works - Step 1" className="item-image"/> */}
                        <GatsbyImage image={image1.gatsbyImageData} alt="How Comparison works - Step 1" className="item-image" objectFit="contain"></GatsbyImage>
                        <div className="item-description uk-grid uk-grid-collapse">
                            <div className="item-description__icon uk-width-1-6">1</div>
                            <div className="item-description__text uk-width-5-6">{step1}</div>
                        </div>
                        
                    </div>
                    <div className="how-works__container_item">
                        {/* <img src={image2} alt="How Comparison works - Step 2" className="item-image"/> */}
                        <GatsbyImage image={image2.gatsbyImageData} alt="How Comparison works - Step 2" className="item-image" objectFit="contain"></GatsbyImage>
                        <div className="item-description uk-grid uk-grid-collapse">
                            <div className="item-description__icon uk-width-1-6">2</div>
                            <div className="item-description__text uk-width-5-6">{step2}</div>
                        </div>
                        
                    </div>
                    <div className="how-works__container_item">
                        {/* <img src={image3} alt="How Comparison works - Step 3" className="item-image"/> */}
                        <GatsbyImage image={image3.gatsbyImageData} alt="How Comparison works - Step 3" className="item-image" objectFit="contain"></GatsbyImage>
                        <div className="item-description uk-grid uk-grid-collapse">
                            <div className="item-description__icon uk-width-1-6">3</div>
                            <div className="item-description__text uk-width-5-6">{step3}</div>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </div>
    )

}

export default Comparison